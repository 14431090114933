<template>
  <!-- 上传图片组件 -->
  <div>
    <div class="avatatImg">
      <a-upload
        name="uploadFile"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        :action="action"
        :before-upload="beforeUpload"
        @change="handleChange"
        :disabled="isDiabled"
      >
        <img v-if="imageUrl" :src="imageUrl" alt="头像" class="avatar" />
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">上传</div>
        </div>
      </a-upload>
      <div class="text">
        <div>{{ avatarTextTop }}</div>
        <div>{{ avatarTextBottom }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
import { domainName } from "@/config/index";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  props: {
    imageUrl: {
      type: String,
      default: "",
    },
    avatarTextTop: {
      type: String,
      default: "推荐使用160*160px,JPG.PNG.JPEG格式",
    },
    avatarTextBottom: {
      type: String,
      default: "图片小于1M",
    },
  },
  data() {
    return {
      action: `${domainName}/admin/files/upload/images`,
      loading: "", // 上传头像的loading图
      isDiabled: false, // 上传是否禁用
    };
  },
  methods: {
    // 上传文件改变时触发
    handleChange(info) {
      this.isDiabled = true;
      setTimeout(() => {
        this.isDiabled = false;
      }, 3000);
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          // this.imageUrl = imageUrl;
          // this.$emit("imageUrlFn", imageUrl);
          this.loading = false;
        });
      }
      let { code, data } = info.file.response;
      if (code === 200) {
        this.isDiabled = false;
        this.$emit("avatarfn", data);
      }
    },
    // 上传图片
    beforeUpload(file) {
      this.$emit("avatarLoadingFn", true);
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("不能上传其他类型的图片");
      }
      // const isLt1M = file.size / 1024 / 1024 < 1;
      // if (!isLt1M) {
      //   message.error("图片的大小不能超过1M");
      // }
      return isJpgOrPng;
    },
  },
};
</script>
 
<style lang="less" scoped>
.avatar {
  width: 160px;
  height: 160px;
}
.ant-upload-picture-card-wrapper {
  width: auto;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.avatatImg {
  display: flex;
  align-items: center;
  .text {
    margin-left: 20px;
    div {
      line-height: 30px !important;
      font-weight: 600;
    }
  }
}
</style>